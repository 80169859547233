import React, { useMemo } from "react"
import { Link } from "gatsby"
import { ReactSVG } from "react-svg"
import Image from "@common/Image/Image"
import arrowBack from "@images/common/ic-arrow-back.svg"
import CircleBLur from "@common/CircleBlur/CircleBlur"
import CatchupSection from "@common/CatchupSection/CatchupSection"
import { MOBILE_MAX_WIDTH } from "@constants"
import useWindowDimensions from "@hooks/useWindowDimensions"
import classNames from "classnames"
import CaseStudyHeader from "./components/CaseStudyHeader/CaseStudyHeader"
import "./CaseStudy.scss"

const CaseStudy = ({ caseStudy }) => {
  const {
    backlightColor,
    eighthSubsectionText,
    firstSubsectionText,
    firstSubsectionImage,
    secondSubsectionText,
    secondSubsectionImage,
    thirdSubsectionText,
    thirdSubsectionImage,
    fourthSubsectionText,
    wideImage,
    fifthSubsectionText,
    fifthSubsectionImage,
    sixthSubsectionText,
    sixthSubsectionImage,
    seventhSubsectionText,
    seventhSubsectionImage,
  } = caseStudy
  const { width } = useWindowDimensions()
  const isMobile = useMemo(
    () => width <= MOBILE_MAX_WIDTH && width !== 0,
    [width]
  )

  const caseStudyName = useMemo(
    () => window.location.pathname.split("/").pop(),
    [window.location.pathname]
  )

  const removeEmptyH2 = () => {
    const cases = ["asistme-ai", "jota", "apify", "hcsg", "esooko"]
    const match = window.location.pathname.match(/case-studies\/([^\/]+)\/?$/)
    const shouldBeRemoved = cases.includes(match[1])
    if (shouldBeRemoved) return "last-item"
  }

  const fourthSubsectionClassName = classNames(
    "case-study__content-text case-study__content-text-start",
    {
      shifted: caseStudyName === "jota",
    }
  )

  return (
    <div className="case-study">
      <Link to="/case-studies">
        <div className="case-study__back-to-case-studies">
          <ReactSVG
            src={arrowBack}
            className="case-study__back-to-case-studies-icon"
            alt="back to case studies"
          />
          <span>Back to Case studies</span>
        </div>
      </Link>
      <CaseStudyHeader caseStudy={caseStudy} />
      <div className="case-study__content">
        <div
          className="case-study__content-text"
          dangerouslySetInnerHTML={{ __html: firstSubsectionText }}
        />
        <div className="case-study__content-image">
          <Image
            src={firstSubsectionImage.url}
            className="case-study-text--image__image"
            alt="Product image"
          />
          <CircleBLur
            showOnMobile
            top={isMobile ? "10%" : "20%"}
            right="20%"
            width="300px"
            height="300px"
            blur="blur(100px)"
            color={`linear-gradient(#${backlightColor}, #${backlightColor})`}
          />
        </div>
      </div>
      <div className="case-study__content">
        <div className="case-study__content-image">
          <Image
            src={secondSubsectionImage.url}
            className="case-study-text--image__image"
            alt="Product image"
          />
          <CircleBLur
            showOnMobile
            top={isMobile ? "10%" : "20%"}
            right="28%"
            width="300px"
            height="300px"
            blur="blur(100px)"
            color={`linear-gradient(#${backlightColor}, #${backlightColor})`}
          />
        </div>
        <div
          className="case-study__content-text"
          dangerouslySetInnerHTML={{ __html: secondSubsectionText }}
        />
      </div>
      <div className="case-study__content">
        <div
          className="case-study__content-text case-study__content-text-start"
          dangerouslySetInnerHTML={{ __html: thirdSubsectionText }}
        />
        {fourthSubsectionText ? (
          <div
            className={fourthSubsectionClassName}
            dangerouslySetInnerHTML={{
              __html: fourthSubsectionText,
            }}
          />
        ) : (
          <div className="case-study__content-image">
            <Image
              src={thirdSubsectionImage.url}
              className="case-study-text--image__image"
              alt="Product image"
            />
            <CircleBLur
              showOnMobile
              top={isMobile ? "10%" : "20%"}
              right="20%"
              width="300px"
              height="300px"
              blur="blur(100px)"
              color={`linear-gradient(#${backlightColor}, #${backlightColor})`}
            />
          </div>
        )}
      </div>
      {wideImage && (
        <>
          <CatchupSection />
          <div className="case-study__main-image-container">
            <Image
              src={wideImage.url}
              className="case-study__main-image"
              alt="Product main image"
            />
          </div>
        </>
      )}
      {fifthSubsectionText && (
        <div className="case-study__content">
          <div
            className="case-study__content-text"
            dangerouslySetInnerHTML={{
              __html: fifthSubsectionText,
            }}
          />
          <div className="case-study__content-image">
            <Image
              src={fifthSubsectionImage.url}
              className="case-study-text--image__image"
              alt="Product image"
            />
            <CircleBLur
              showOnMobile
              top={isMobile ? "10%" : "20%"}
              right="20%"
              width="300px"
              height="300px"
              blur="blur(100px)"
              color={`linear-gradient(#${backlightColor}, #${backlightColor})`}
            />
          </div>
        </div>
      )}
      {sixthSubsectionImage && (
        <div className="case-study__content">
          <div className="case-study__content-image">
            <Image
              src={sixthSubsectionImage.url}
              className="case-study-text--image__image"
              alt="Product image"
            />
            <CircleBLur
              showOnMobile
              top={isMobile ? "10%" : "25%"}
              right="28%"
              width="300px"
              height="300px"
              blur="blur(100px)"
              color={`linear-gradient(#${backlightColor}, #${backlightColor})`}
            />
          </div>
          <div
            className="case-study__content-text"
            dangerouslySetInnerHTML={{
              __html: sixthSubsectionText,
            }}
          />
        </div>
      )}
      {(seventhSubsectionImage ||
        seventhSubsectionText ||
        eighthSubsectionText) && (
        <div className="case-study__content">
          {seventhSubsectionImage && (
            <div className="case-study__content-image">
              <Image
                src={seventhSubsectionImage.url}
                className="case-study-text--image__image"
                alt="Product image"
              />
              <CircleBLur
                showOnMobile
                top={isMobile ? "10%" : "25%"}
                right="28%"
                width="300px"
                height="300px"
                blur="blur(100px)"
                color={`linear-gradient(#${backlightColor}, #${backlightColor})`}
              />
            </div>
          )}
          <div className="case-study__content-text">
            <div
              // style={{width: '50%' && eighthSubsectionText}}
              dangerouslySetInnerHTML={{
                __html: seventhSubsectionText,
              }}
            />
          </div>
          {eighthSubsectionText && (
            <div className="case-study__content-text" style={{ margin: 0 }}>
              <div
                className={removeEmptyH2() ?? ""}
                dangerouslySetInnerHTML={{
                  __html: eighthSubsectionText,
                }}
              />
            </div>
          )}
        </div>
      )}
      <CatchupSection title="Say hello to our team" />
    </div>
  )
}

export default CaseStudy
