import React from "react";
import impressitMetaImage from "@images/meta/impressit-meta-image.jpg";
import { Helmet } from "react-helmet";
import { Script } from "gatsby";
import FAQSchemaGenerator from "../../../utils/FAQSchemaGenerator";
import NanutoSans from '../../../../static/fonts/NunitoSans.woff2'
import usePreloadLinks from "../../../hooks/usePreloadLinks";
import { getMetaTitleFromData, getTitleFromData } from "../../../utils/SEODataHelper";

const SEO = ({
    description,
    title,
    SEOData,
    keywords,
    titleTemplate,
    image,
    path,
    url,
    ownMeta,
    schema,
    faqSchema,
    lang,
    location,
    isNotIndexed
}) => {
    const metaTitle = getMetaTitleFromData(SEOData)
    const currentTitle = getTitleFromData(SEOData, title)

    return (
        <>
            <Script type="application/ld+json">{JSON.stringify(schema)}</Script>
            {faqSchema && (
                <Script type="application/ld+json">
                    {JSON.stringify(FAQSchemaGenerator.generateFAQ(faqSchema))}
                </Script>
            )}
            <Helmet
                htmlAttributes={{
                    lang,
                }}
                title={currentTitle}
                keywords={[keywords]}
                meta={[
                    ownMeta,
                    (metaTitle
                        ? {
                            name: `title`,
                            content: metaTitle,
                          }
                        : {}),
                    {
                        name: `description`,
                        content: description,
                    },
                    {
                        name: `robots`,
                        content: location?.search || isNotIndexed ? `none` : `all`,
                    },
                    {
                        property: `og:url`,
                        content: path ? url + path : url,
                    },
                    {
                        property: `og:image:height`,
                        content: `200`,
                    },
                    {
                        property: `og:image:width`,
                        content: `200`,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `og:site_name`,
                        content: `impressit.io`,
                    },
                    {
                        property: `og:title`,
                        content: currentTitle,
                    },
                    {
                        property: `og:description`,
                        content: description,
                    },
                    {
                        property: `og:image`,
                        content: image,
                    },
                    {
                        property: `og:image:secure_url`,
                        content: image,
                    },
                    {
                        property: `twitter:card`,
                        content: `summary_large_image`,
                    },
                    {
                        property: `twitter:site`,
                        content: `impressit.io`,
                    },
                    {
                        property: `twitter:url`,
                        content: path ? url + path : url,
                    },
                    {
                        property: `twitter:title`,
                        content: currentTitle,
                    },
                    {
                        property: `twitter:description`,
                        content: description,
                    },
                    {
                        property: `twitter:image`,
                        content: image,
                    },
                ]}
                link={[
                    {
                        rel: 'preload',
                        href: NanutoSans,
                        as: 'font',
                        type: 'font/woff2',
                        crossOrigin: "anonymous"
                    },
                ]}
            />
        </>
    )
};

SEO.defaultProps = {
    lang: "en",
    meta: [],
    title: "",
    SEOData: {},
    description: "",
    image: impressitMetaImage,
    url: "https://impressit.io",
    ownMeta: {},
    schema: {
        "@context": "https://schema.org",
        "@type": "Organization",
        name: "Impressit",
        url: "https://impressit.io/",
        logo: "/static/impressit-meta-image-4960d4e6d9e29f7810c04f504c2d2f1a.jpg",
    },
};

export default SEO;
